/** Copyright �2014 RenoWorks, Inc. All rights reserved. www.renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function ($) {
  $.fn.rw_spinner = function (options) {
    var settings = $.extend({
      color: '#888',
      style: 1,
      size: null,
    }, options);

    var spinner = $('<div/>');
    $(this).append(spinner);
    var R1 = 30,
      R2 = 10;
    if (settings.size) {
      R1 = settings.size / 2;
    }
    switch (settings.style) {
      case 2: setup2(spinner); break;
      default: setup(spinner, R1, R2, 8, settings.color);
    }

    function setup(holder, R1, R2, count, color) {
      var width = R1 * 2 + R2 * 2,
        height = R1 * 2 + R2 * 2,
        r = Raphael(holder[0], width, height),
        sectors = [],
        opacity = [],
        radius = [],
        beta = 2 * Math.PI / count,
        params = { stroke: color, 'stroke-width': 0, fill: color };
      Raphael.getColor.reset();
      for (var i = 0; i < count; i++) {
        var alpha = beta * i - Math.PI / 2,
          cos = Math.cos(alpha),
          sin = Math.sin(alpha),
          x = R1 * cos + R1 + R2,
          y = R1 * sin + R1 + R2;
        opacity[i] = 1 / count * i;
        radius[i] = R2 * ((0.5 * opacity[i]) + 0.5);
        sectors[i] = r.circle(x, y, R2 / 2).attr(params);
      }
      var tick;
      (function ticker() {
        opacity.unshift(opacity.pop());
        radius.unshift(radius.pop());
        for (var i = 0; i < count; i++) {
          sectors[i].attr('opacity', opacity[i]);
          sectors[i].attr('r', radius[i]);
        }

        if (typeof r.safari === 'function') {
          r.safari();
        }
        tick = setTimeout(ticker, 1000 / count);
      }());
    }

    function setup2(holder) {
      var r = Raphael(holder[0], 80, 80);
      var paths = [];
      paths[0] = r.path('M0 16L32 0L32 14L13 24L13 72L0 72L0 16').attr({ 'stroke-width': 0, fill: '#4c4c4c' });
      paths[1] = r.path('M32 0L46 7L46 72L32 72L32 0').attr({ 'stroke-width': 0, fill: '#f05b25' });
      paths[2] = r.path('M49 9L63 16L63 72L49 72L49 9').attr({ 'stroke-width': 0, fill: '#be2e1b' });
      paths[3] = r.path('M66 18L80 25L80 72L66 72L66 18').attr({ 'stroke-width': 0, fill: '#891d03' });
      var state = 0;
      (function ticker() {
        switch (state) {
          case 0: paths[0].attr('opacity', 0.5);
            paths[1].attr('opacity', 0.5);
            paths[2].attr('opacity', 0.5);
            paths[3].attr('opacity', 0.5); break;
          case 1: paths[0].attr('opacity', 1); break;
          case 2: paths[1].attr('opacity', 1); break;
          case 3: paths[2].attr('opacity', 1); break;
          case 4: paths[3].attr('opacity', 1); break;
          default: break;
        }

        if (typeof r.safari() === 'function') {
          r.safari();
        }
        setTimeout(ticker, 1000 / 4);
        state++;
        if (state == 7) {
          state = 0;
        }
      }());
    }
  };
}(jQuery));
