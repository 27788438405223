(function ($) {
  $.fn.pagination = function (options) {
    var settings = $.extend({
      perPage: 8,
      controls: null,
      height: null,
      width: null,
      paginator: null,
      ajax: null,
      animationSpeed: 800,
      updateOnResize: null,

    }, options);

    var containerWidth,
      wrapperWidth,
      containerHeight;

    $(this).each(function () {
      var $this = $(this);

      var scrolling = false;

      // Height
      if (settings.height !== null) {
        var containerHeight = settings.height;
      } else {
        var containerHeight = $this.height();
      }

      // Width

      setWidth();

      if (settings.ajax === null) {
        var blockCount = $this.children().length;

        /* if(blockCount < 1){
					$(".polaroid_gallery").html('<h2 class="no_results">- No Results to Display -</h2>');
					return false;
				} */

        var blocks = $this.children();
        var currentPage = 1;
        var pageCount = Math.ceil(blockCount / settings.perPage);

        var arraySlices = [];
        for (var i = 0; i < pageCount; i++) {
          arraySlices[i] = $(this).children().slice((i) * settings.perPage, (i + 1) * settings.perPage);
        }
      } else {
        // Get total pages
        var blockCount = ajaxGridOptionCount();

        var currentPage = 1;

        var pageCount = Math.ceil(blockCount / settings.perPage);

        // Load first and second page
        ajaxLoadGridOptions(0, settings.perPage, 2, 'filters', $this);
      }

      var wrapperWidth = containerWidth * pageCount;
      var pageHTML = $('<div class="pagination_wrapper" style="left:0px; width:' + wrapperWidth + 'px; height: ' + containerHeight + 'px; position: relative;"></div>');

      $.each(arraySlices, function () {
        var page = $('<div class="pagination_page"></div>');
        page.css({ height: containerHeight + 'px', width: containerWidth });
        page.html($(this));
        pageHTML.append(page);
      });

      $(this).html(pageHTML);

      // Click Events
      $(this).next(settings.controls).on('click', '.next', function () {
        if (scrolling == true) return;

        if (currentPage == pageCount) {
          scrolling = true;
          $this.children('.pagination_wrapper').animate({ left: '0px' }, settings.animationSpeed, function () {
            scrolling = false;
          });

          currentPage = 1;
          updatePaginator(currentPage, pageCount, $this);
          return;
        }

        var currentX = $this.children('.pagination_wrapper').css('left');
        currentX = currentX.replace('px', '');

        if (settings.updateOnResize) {
          containerWidth = $this.width();
        }

        if (containerWidth == 0) {
          containerWidth = $this.width();
        }

        scrolling = true;
        $this.children('.pagination_wrapper').animate({ left: Number(currentX) - Number(containerWidth) }, settings.animationSpeed, function () {
          scrolling = false;
        });

        currentPage++;
        updatePaginator(currentPage, pageCount, $this);
      });

      $(this).next(settings.controls).on('click', '.prev', function () {
        if (scrolling == true) return;

        if (currentPage === 1) {
          scrolling = true;
          var farLeft = (pageCount - 1) * containerWidth;
          $this.children('.pagination_wrapper').animate({ left: '-' + farLeft + 'px' }, settings.animationSpeed, function () {
            scrolling = false;
          });

          currentPage = pageCount;
          updatePaginator(currentPage, pageCount, $this);
          return;
        }

        if (settings.ajax) {
        }

        var currentX = $this.children('.pagination_wrapper').css('left');
        currentX = currentX.replace('px', '');

        if (settings.updateOnResize) {
          containerWidth = $this.width();
        }

        if (containerWidth == 0) {
          containerWidth = $this.width();
        }

        scrolling = true;
        $this.children('.pagination_wrapper').animate({ left: Number(currentX) + Number(containerWidth) }, settings.animationSpeed, function () {
          scrolling = false;
        });

        currentPage--;
        updatePaginator(currentPage, pageCount, $this);
      });

      if (settings.updateOnResize) {
        resetSizing($this, pageCount, currentPage);
      }

      $(window).on('resize', function (e) {
        if (settings.updateOnResize) {
          resetSizing($this, pageCount, currentPage);
        }
        setWidth();
      });

      updatePaginator(currentPage, pageCount, $this);
    });

    function setWidth() {
      if (!settings.width) {
        containerWidth = $(this).width();
        if (containerWidth << 0 || containerWidth == 0) {
          containerWidth = $(window).width() - 80;
        }
      } else {
        containerWidth = settings.width;
      }

      $('.polaroid_gallery .pagination_page').width(containerWidth);
      var pages = $('.polaroid_gallery .pagination_wrapper').children('.pagination_page').size();
      $('.polaroid_gallery .pagination_wrapper').width(pages * containerWidth);
    }

    function updatePaginator(n, c, context) {
      if (settings.paginator == null) return;
      // console.log(n, c, context);
      context.siblings(settings.paginator).html('<h3>' + n + '/' + c + '</h3>');
      context.siblings().children(settings.paginator).html('<h3>' + n + '/' + c + '</h3>');
    }

    function ajaxLoadGridOptions(offset, limit, pageCount, filters, context) {
      // Alternatively Rob, we could pass count in the AJAX call as well and let multiple pages be handled on the backend
      for (var i = 0; i < pageCount; i++) {
        $.ajax({
          url: settings.ajax,
          type: 'POST',
          dataType: 'JSON',
          data: 'data',
          complete: function (r) {
            fakeGridData = [
              ['12345', 'Athena', 'sites/dutchboy/img/fake_thumb.png'],
              ['123465', 'Athena2', 'sites/dutchboy/img/fake_thumb.png'],
              ['123457', 'Athena3', 'sites/dutchboy/img/fake_thumb.png'],
              ['123485', 'Athen4a', 'sites/dutchboy/img/fake_thumb.png'],
              ['123459', 'Athena5', 'sites/dutchboy/img/fake_thumb.png'],

            ];

            ajaxAddPage(fakeGridData, context);
          },
        });
      }
    }

    function ajaxGridOptionCount(filters) {
      return 1000;

      $.ajax({
        url: settings.ajaxSource,
        type: 'POST',
        dataType: 'JSON',
        data: {
          product_category: data.product_category,
        },
        complete: function (r) {
          updatePaginator(currentPage, pageCount, $this);
        },
      });
    }

    function ajaxAddPage(gridData, context) {
      // do html for page
      var pageHTML = $('<div class="pagination_wrapper" style="left:0px; width:' + wrapperWidth + 'px; height: ' + containerHeight + 'px; position: relative;"></div>');
      $.each(gridData, function () {
        var page = $('<div class="pagination_page"></div>');
        page.css({ height: containerHeight + 'px', width: containerWidth });
        page.html('html');
        pageHTML.append(page);
      });
      context.html(pageHTML);
      // expand wrapper
      // add html to wrapper
    }

    function resetSizing(context, pageCount, currentPage) {
      if (settings.height !== null) {
        var containerHeight = settings.height;
      } else {
        var containerHeight = $('.modal_content.ui-content').height();
      }

      // Width
      if (settings.width !== null) {
        var containerWidth = settings.width;
      } else {
        var containerWidth = $('.modal_content.ui-content').width();
      }

      var wrapperWidth = containerWidth * pageCount;
      var wrapperScroll = containerWidth * (currentPage - 1);

      $('.pagination_wrapper', context).css({ width: wrapperWidth + 'px' });
      $('.pagination_wrapper', context).css({ height: containerHeight + 'px' });
      $('.pagination_wrapper', context).css({ left: '-' + wrapperScroll + 'px' });
      $('.pagination_page', context).css({ width: containerWidth + 'px' });
      $('.pagination_page', context).css({ height: containerHeight + 'px' });
    }

    function realWidth(obj) {
		    var clone = obj.parents('.body').clone();
		    clone.css('visibility', 'hidden');
		    $('body').append('<div class="dialog fakedialog"></div>');
		    $('.fakedialog').append(clone);
		    var width = $('.fakedialog .main').outerWidth();
		    // $('.fakedialog').remove();
		    return width;
    }
  };
}(jQuery));
